<template>
  <div>
    <el-dialog
      title="Edit Basic Information"
      :visible.sync="showDialog"
      width="50%"
      :close-on-click-modal="false"
    >
      <el-form
        class="login-form"
        :rules="rules"
        :model="model"
        ref="form"
        label-position="top"
        :validate-on-rule-change="false"
      >
        <div class="row">
          <div class="col-md-6">
            <el-form-item
              prop="first_name"
              :label="$t('registerDetail.firstName')"
            >
              <el-input
                v-model="model.first_name"
                :placeholder="$t('registerDetail.firstName')"
                prefix-icon="el-icon-user"
                maxlength="100"
              ></el-input>
            </el-form-item>
          </div>
          <div class="col-md-6">
            <el-form-item
              prop="last_name"
              :label="$t('registerDetail.lastName')"
            >
              <el-input
                v-model="model.last_name"
                :placeholder="$t('registerDetail.lastName')"
                prefix-icon="el-icon-user"
                maxlength="100"
              ></el-input>
            </el-form-item>
          </div>
        </div>
        <el-form-item prop="country" label="Country">
          <div>
            <vue-country-intl
              v-model="model.country"
              :showAreaCode="false"
              placeholder="Select Country"
              type="country"
            >
              <span slot="vueCountryNoData">
                No Data
              </span>
            </vue-country-intl>
          </div>
        </el-form-item>
        <el-form-item
          prop="graduation_year"
          v-if="role === 'student'"
          label="Grade Level"
          required
        >
          <GradeLevelSelector
            :value="model.graduation_year"
            @change="
              value => {
                model.graduation_year = value;
              }
            "
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false">Cancel</el-button>
        <el-button type="primary" @click="handleSave">
          <i class="fas fa-save"></i>
          Save
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user, instant } from "@ivy-way/material";
import moment from "moment";
import formMixin from "@/mixins/form";
import GradeLevelSelector from "@/views/login/GradeLevel";
import Edit from "@/views/profile/apis/Edit";

export default {
  metaInfo() {},

  components: { GradeLevelSelector },

  mixins: [formMixin],

  props: ["myPlans", "forAdmin", "role", "userInfo"],
  data() {
    return {
      moment,
      countdown: 0,
      isCounting: false,
      model: {
        first_name: "",
        last_name: "",
        education_level: 4,
        graduation_year: "",
        country: ""
      },
      errors: {
        email: "",
        captcha: ""
      },
      showDialog: false
    };
  },
  computed: {
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    rules() {
      return {
        first_name: [
          {
            required: true,
            message: this.$t("register.please_input"),
            trigger: "blur"
          }
        ],
        last_name: [
          {
            required: true,
            message: this.$t("register.please_input"),
            trigger: "blur"
          }
        ],
        country: [
          {
            required: true,
            message: this.$t("register.please_input"),
            trigger: "blur"
          }
        ]
      };
    }
  },
  watch: {
    userInfo() {
      this.model = { ...this.userInfo };
    }
  },

  mounted() {
    this.model = { ...this.userInfo };
  },

  methods: {
    openEditUserDialog() {
      this.showDialog = true;
    },
    async handleSave() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          let infoData = null;
          if (this.role === "student") {
            infoData = {
              first_name: this.model.first_name,
              last_name: this.model.last_name,
              country: this.model.country,
              education_level: 4,
              graduation_year: Number(this.model.graduation_year)
            };
          } else {
            infoData = {
              first_name: this.model.first_name,
              last_name: this.model.last_name,
              country: this.model.country
            };
          }
          try {
            await Edit.changeProfile({ ...infoData });
            this.showDialog = false;
            this.$message.success("Updated successfully");
            this.$emit("getUserProfile");
          } catch (error) {
            // this.$message.error(error.message || "Update failed");
          }
        }
      });
    }
  }
};
</script>

<style scoped>
.free-color {
  color: #01b884;
}

::v-deep .el-dialog__footer {
  padding: 20px;
  border-top: 1px solid #ebeef5;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}
</style>

<style scoped>
::v-deep .el-form-item__label {
  line-height: 1rem;
}
::v-deep .vue-country-intl ::placeholder {
  color: #c0c4cc;
}
::v-deep .vue-country-list {
  list-style: none;
  padding: 0;
}
::v-deep .vue-country-intl .country-intl-label {
  padding: 0 15px;
  line-height: 40px;
}
::v-deep .vue-country-intl .country-intl-label span {
  vertical-align: unset;
}
::v-deep .vue-country-item:not(.selected):hover {
  color: #fff;
  background-color: var(--themeColor);
}
</style>
