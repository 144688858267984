<template>
  <div>
    <el-radio-group
      v-model="test_id"
      class="mb-2"
      @input="test => getUserPerformance()"
    >
      <el-radio
        v-for="(test, index) in Tests"
        :key="index"
        :label="test.id"
        :value="test.id"
        border
      >
        <b class="test-color">{{ test.name }}</b>
      </el-radio>
    </el-radio-group>
    <template v-if="tag_analysis_box">
      <div
        v-for="(tag_analysis, section) in tag_analysis_box"
        :key="section"
        v-show="section !== 'Unset'"
      >
        <Heading heading="h4" :content="section"></Heading>
        <hr />
        <div class="mb-2" v-if="tag_analysis.length > 0">
          <Performance
            section="Reading"
            :tagType="``"
            :tags="tag_analysis"
            :testType="testType"
          ></Performance>
        </div>
        <template v-else>
          <el-empty description="No Data"></el-empty>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { Decimal } from "decimal.js";
import echarts from "echarts";
import _ from "lodash";
import Performance from "@/views/profile/components/Performance.vue";
import userApi from "@/apis/user";

export default {
  components: { Performance },

  mixins: [],

  props: ["userId"],
  data() {
    return {
      Tests: [
        {
          id: 4,
          name: "SAT"
        },
        {
          id: 1,
          name: "ACT"
        }
      ],
      test_id: 4,
      tag_analysis_box: null
    };
  },
  computed: {
    testType(){
      let name = "SAT";
      this.Tests.forEach( test =>{
        if(this.test_id === test.id){
          name = test.name;
        }
      });
      return name;
    }
  },
  watch: {
    userId() {
      this.getUserPerformance();
    }
  },

  mounted() {
    this.getUserPerformance();
  },

  methods: {
    async getUserPerformance() {
      if (this.userId) {
        this.tag_analysis_box = null;
        const res = await userApi.getUserPerformance(this.userId, {
          test_id: this.test_id
        });
        this.tag_analysis_box = res;
      }
    }
  }
};
</script>

<style scoped></style>
