var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"progress-section",class:_vm.isFull ? '' : 'row'},_vm._l((_vm.tags),function(tag,index){return _c('div',{key:index,staticClass:"tag-progress",class:_vm.isFull ? '' : 'col-md-6'},[_c('div',{staticClass:"tag-header"},[_c('div',{staticClass:"tag-title"},[_c('h3',{staticClass:"mb-0",staticStyle:{"cursor":"pointer"}},[_vm._v(" "+_vm._s(tag.name)+" ")]),_c('div',{staticClass:"score-range"},[_c('el-tooltip',{attrs:{"effect":"dark","placement":"top","content":"This is your score prediction if the entire test contains questions from this topic.","visible-arrow":false}},[_c('span',[_vm._v(" Performance "),_c('i',{staticClass:"fas fa-question-circle"}),_vm._v(" : "+_vm._s(_vm.getScoreRange( tag.user_correct_count, tag.user_answered_count ))+" ")])])],1)])]),_c('div',{staticClass:"Bar-box"},[_c('div',{staticClass:"tag-subtitle"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.$t('pageTitle.Your cumulative average'),"placement":"top"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("pageTitle.My Average"))+" "),_c('i',{staticClass:"fa fa-question-circle question-icon"}),_vm._v("   "),_c('span',{domProps:{"innerHTML":_vm._s(
                  _vm.getAvg(tag.user_correct_count, tag.user_answered_count)
                )}})])])],1),_c('div',{staticClass:"progress-bars"},[_c('div',{staticClass:"progress-container"},[_c('el-progress',{staticStyle:{"width":"100%"},attrs:{"stroke-width":20,"show-text":false,"color":"#409efe","percentage":_vm.getAvgProgress(
                  tag.user_correct_count,
                  tag.user_answered_count
                )}})],1)])]),_c('div',{staticClass:"Bar-box"},[_c('div',{staticClass:"tag-subtitle"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.$t('pageTitle.Everyone’s average for this question type'),"placement":"top"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("pageTitle.Average"))+" "),_c('i',{staticClass:"fa fa-question-circle question-icon"}),_vm._v("   "),_c('span',{domProps:{"innerHTML":_vm._s(
                  _vm.getAvg(tag.total_correct_count, tag.total_answered_count)
                )}})])])],1),_c('div',{staticClass:"progress-bars"},[_c('div',{staticClass:"progress-container"},[_c('el-progress',{staticStyle:{"width":"100%"},attrs:{"stroke-width":20,"show-text":false,"color":"#2c3e50","percentage":_vm.getAvgProgress(
                  tag.total_correct_count,
                  tag.total_answered_count
                )}})],1)])]),(tag.children && tag.children.length > 0)?_c('div',{staticClass:"text-primary toggle-button"},[_c('div',{on:{"click":function($event){return _vm.toggleChildren(tag)}}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(tag.isExpanded),expression:"tag.isExpanded"}]},[_c('i',{staticClass:"fas fa-chevron-up"}),_vm._v("   Hide Skill/Knowledge Testing Point ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!tag.isExpanded),expression:"!tag.isExpanded"}]},[_c('i',{staticClass:"fas fa-chevron-down"}),_vm._v("   View Skill/Knowledge Testing Point ")])]),_c('div',[_c('transition',{attrs:{"name":"slide"}},[(tag.children && tag.children.length > 0 && tag.isExpanded)?_c('div',{staticClass:"children-tags"},[_c('Performance',{attrs:{"tags":tag.children,"section":_vm.section,"tag-type":_vm.tagType,"exam-id":_vm.examId,"user-exam-id":_vm.userExamId,"test-type":_vm.testType,"isFull":true}})],1):_vm._e()])],1)]):_vm._e(),_c('hr')])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }