import ApiService from "@/common/api.service";

export default {
  sendEmailCode(data) {
    return ApiService.post("/change-email-verify", data);
  },
  changeEmail(data) {
    return ApiService.post("/change-email-confirm", data);
  },
  changeProfile(data) {
    return ApiService.patch("/user-info", data);
  },
  changePassword(data) {
    return ApiService.post("/change-password", data);
  }
};
